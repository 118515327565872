.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.modal-cancel-btn{
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.overflow-scroll{
  overflow-y: scroll;
}

.site-container{
  margin: 20px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-content: center; */
  height: 90vh;
}
.centered-row{
  display: flex;
  justify-content: center;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.skipCountdownCheckbox{
  border-radius: 5px;
  background-color: rgb(20, 21, 23);
  padding: 10px;
}
